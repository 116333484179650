<template>
  <div id="reset-password" class="uk-height-viewport">
    <div class="uk-flex uk-flex-center">
      <div class="uk-width-auto" style="min-width: 480px;">

        <div style="height:200px">
          <img class="uk-margin-large-top" src="@/assets/IE-Logo.png" height="220" width="448" alt="CMS3" />
        </div>
        
        <div class="custom-card-bg uk-card-body uk-box-shadow-xlarge">

          <form @submit.prevent="" novalidate class="uk-form-stacked uk-margin"> 
            <div class="uk-margin-top">
              <div class="uk-margin">
                <div class="uk-inline uk-width-1-1 uk-text-center uk-text uk-text-lead">
                  <span>
                    <span>{{email}}</span>
                    <span class="uk-margin-small-left invalid-icon" v-if="verificationFailed" uk-tooltip="Verification Failed" uk-icon="icon: warning" ></span>
                  </span>
                </div>
              </div>
            </div>
          </form>

          <div v-if="!isLoading" class="uk-margin uk-text-center" >
            <router-link :to="{ name: 'LandingPage' }">
              <button class="uk-button uk-button-primary">{{ $t('Home') }}</button>
            </router-link>
          </div>
          <div v-else>
            <div uk-spinner="ratio: 0.5"></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Notification from '@/components/util/Notification';

export default {
    name: 'EmailVerification',
    data () {
      return {
        isLoading: false,
        verificationFailed: false
      }
    },
    computed: {
      token () {
        return this.$route.query.token
      },
      email () {
        return this.$route.query.email
      }
    },
    async mounted () {
      if (!this.token || !this.email) {
        return Notification.showNotification(this.$t('Pages.SignUp.LinkExpired'), response || this.$t('Pages.SignUp.LinkExpiredDesc'), 'error')
      }
      this.isLoading = true
      const response = await this.$store.dispatch('verifyUserEmail', { token: this.token, email: this.email })
      this.isLoading = false
      if (response?.errors?.length) {
        this.verificationFailed =  true
        return Notification.showNotification(this.$t('Generic.Messages.UnSuccessful'), response?.errors[0]?.message, 'error')
      } else {
        Notification.showNotification(this.$t('Generic.Messages.Successful'), this.$t('Pages.SignUp.VerificationSuccessful'))
        setTimeout(() => {
          return this.$router.replace({ name: 'Login' })
        }, 500)
      }

    }
}
</script>
